<template>
	<div class="donation-page-form-section donation-page-form-section-iban">
		<h2 class="form-subtitle">{{ $t( 'donation_form_payment_bankdata_title' ) }}</h2>

		<FormButton
			class="calculate-iban-button"
			:is-outlined="true"
			@click.prevent="showCalculator = !showCalculator"
			aria-controls="iban-calculator"
			:aria-expanded="showCalculator"
		>
			Calculate IBAN
		</FormButton>

		<form class="iban-calculator" id="iban-calculator" :class="{ 'visible': showCalculator }" @submit.prevent="submitBankAccount">
			<ScrollTarget target-id="iban-calculator-scroll-target"/>
			<div class="iban-calculator-content">
				<h2 class="icon-title">
					<BankIcon/>
					IBAN Calculator
				</h2>

				<button class="iban-calculator-close" @click.prevent="showCalculator = false" aria-controls="iban-calculator">
					<span class="is-sr-only">{{ $t( 'close' ) }}</span>
					<CloseIcon/>
				</button>

				<div class="iban-calculator-pages" :class="{ 'page-2': calculatorPage === 2 }">
					<div class="iban-calculator-scroller">
						<div class="iban-calculator-page">
							<p>Enter your bank account number and bank code to calculate your IBAN</p>
							<TextField
								v-model="accountNumber"
								name="account-number"
								input-id="account-number"
								:label="$t( 'donation_form_payment_bankdata_account_legacy_label' )"
								placeholder=""
								:show-error="accountNumberError"
								error-message=""
								@field-changed="validateAccountNumber"
							/>

							<ScrollTarget target-id="bank-code-scroll-target"/>
							<TextField
								v-model="bankCode"
								name="bank-code"
								input-id="bank-code"
								:label="$t( 'donation_form_payment_bankdata_bank_legacy_label' )"
								:placeholder="$t( 'donation_form_payment_bankdata_bank_legacy_placeholder' )"
								:show-error="bankCodeError"
								error-message=""
								@field-changed="validateBankCode"
							/>

							<ErrorSummary :is-visible="showCalculatorErrorSummary" :items="[
								{
									validity: accountNumberError || bankCodeError ? Validity.INVALID : Validity.VALID,
									message: $t( 'donation_form_payment_bankdata_error' ),
									focusElement: accountNumberError ? 'account-number' : 'bank-code',
									scrollElement: 'iban-calculator-scroll-target'
								}
							]"/>

							<FormButton button-type="submit">Calculate IBAN</FormButton>
						</div>

						<div class="iban-calculator-page iban-calculator-results">
							<div>
								<p>This is your calculated bank information:</p>
								<ul class="iban-calculator-results-list">
									<li><strong>Bank Account Number:</strong> {{ accountNumber }}</li>
									<li><strong>Bank Code:</strong> {{ bankCode }}</li>
									<li><strong>IBAN:</strong> {{ foundIban }}</li>
									<li><strong>BIC:</strong> {{ foundBic }}</li>
									<li><strong>Bank Name:</strong> {{ foundBankName }}</li>
								</ul>
								<p>Would you like to use it?</p>
							</div>

							<div class="iban-calculator-results-buttons">
								<FormButton @click.prevent="confirmResult">Yes</FormButton>
								<FormButton :is-outlined="true" @click.prevent="tryAgain">No</FormButton>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<form class="iban-form" @submit.prevent="() => {}">
			<IbanField
				v-model="iban"
				:bank-name="bankName"
				:bic="bic"
				:show-error="showIbanError"
				@field-changed="validateIban"
			/>
		</form>
	</div>
</template>

<script setup lang="ts">

import { useStore } from 'vuex';
import { computed, inject, onMounted, ref } from 'vue';
import IbanField from '@src/components/shared/form_fields/IbanField.vue';
import FormButton from '@src/components/shared/form_elements/FormButton.vue';
import CloseIcon from '@src/components/shared/icons/CloseIcon.vue';
import TextField from '@src/components/shared/form_fields/TextField.vue';
import BankIcon from '@src/components/shared/icons/BankIcon.vue';
import { BankValidationResource } from '@src/api/BankValidationResource';
import { action } from '@src/store/util';
import { BankAccountResponse } from '@src/view_models/BankAccount';
import ErrorSummary from '@src/components/shared/validation_summary/ErrorSummary.vue';
import { Validity } from '@src/view_models/Validity';
import ScrollTarget from '@src/components/shared/ScrollTarget.vue';

const bankValidationResource = inject<BankValidationResource>( 'bankValidationResource' );
const store = useStore();
const iban = ref<string>( store.getters[ 'bankdata/iban' ] );
const bic = computed<string>( () => store.getters[ 'bankdata/bic' ] );
const bankName = computed<string>( () => store.getters[ 'bankdata/bankName' ] );
const showIbanError = computed<boolean>( () => store.state.bankdata.validity.iban === Validity.INVALID );

const showCalculator = ref<boolean>( false );
const calculatorPage = ref<1|2>( 1 );
const accountNumber = ref<string>( '' );
const bankCode = ref<string>( '' );
const accountNumberError = ref<boolean>( false );
const bankCodeError = ref<boolean>( false );
const showCalculatorErrorSummary = ref<boolean>( false );
const foundIban = ref<string>( '' );
const foundBic = ref<string>( '' );
const foundBankName = ref<string>( '' );

const tryHideErrorSummary = () => {
	if ( !showCalculatorErrorSummary.value ) {
		return;
	}

	showCalculatorErrorSummary.value = accountNumberError.value || bankCodeError.value;
};

const validateAccountNumber = () => {
	accountNumberError.value = accountNumber.value === '';
	tryHideErrorSummary();
};

const validateBankCode = () => {
	bankCodeError.value = bankCode.value === '';
	tryHideErrorSummary();
};

const submitBankAccount = async () => {
	validateAccountNumber();
	validateBankCode();

	if ( accountNumberError.value || bankCodeError.value ) {
		showCalculatorErrorSummary.value = true;
		return;
	}
	bankValidationResource.validateBankNumber( {
		accountNumber: accountNumber.value,
		bankCode: bankCode.value,
	} ).then( ( response: BankAccountResponse ) => {
		accountNumber.value = response.accountNumber;
		bankCode.value = response.bankCode;
		foundIban.value = response.iban;
		foundBic.value = response.bic;
		foundBankName.value = response.bankName;
		calculatorPage.value = 2;
	} ).catch( () => {
		showCalculatorErrorSummary.value = true;
		accountNumberError.value = true;
		bankCodeError.value = true;
	} );
};

const validateIban = () => {
	store.dispatch( action( 'bankdata', 'setIban' ), iban.value );
	if ( iban.value === '' ) {
		store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.INVALID );
		store.dispatch( action( 'bankdata', 'setBic' ), '' );
		store.dispatch( action( 'bankdata', 'setBankName' ), '' );
		return;
	}

	bankValidationResource.validateIban( {
		iban: iban.value,
	} ).then( ( response: BankAccountResponse ) => {
		store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.VALID );
		store.dispatch( action( 'bankdata', 'setIban' ), response.iban );
		store.dispatch( action( 'bankdata', 'setBic' ), response.bic );
		store.dispatch( action( 'bankdata', 'setBankName' ), response.bankName );
	} ).catch( () => {
		store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.INVALID );
		store.dispatch( action( 'bankdata', 'setBic' ), '' );
		store.dispatch( action( 'bankdata', 'setBankName' ), '' );
	} );
};

const confirmResult = () => {
	store.dispatch( action( 'bankdata', 'setIbanValidity' ), Validity.VALID );
	store.dispatch( action( 'bankdata', 'setIban' ), foundIban.value );
	store.dispatch( action( 'bankdata', 'setBic' ), foundBic.value );
	store.dispatch( action( 'bankdata', 'setBankName' ), foundBankName.value );
	showCalculator.value = false;
	calculatorPage.value = 1;
};

const tryAgain = () => {
	calculatorPage.value = 1;
};

onMounted( () => {
	if ( iban.value !== '' ) {
		validateIban();
	}
} );

store.watch( ( state, getters ) => getters[ 'bankdata/iban' ], ( newIban: string ) => {
	iban.value = newIban;
} );

</script>

<style lang="scss">
@use '@src/scss/settings/global';
@use '@src/scss/settings/colors';
@use '@src/scss/settings/units';
@use '@src/scss/settings/breakpoints';
@use 'sass:map';
@use 'sass:color';

.donation-page-form-section-iban {
	position: relative;

	.calculate-iban-button {
		height: map.get(units.$spacing, 'medium');
		width: auto;
		padding: 0 10px;
		position: absolute;
		top: map.get(units.$spacing, 'small');
		right: map.get(units.$spacing, 'small');

		@include breakpoints.tablet-up {
			top: map.get(units.$spacing, 'large');
			right: map.get(units.$spacing, 'large');
		}
	}
}

.iban-calculator {
	position: relative;
	width: 100%;
	height: 0;
	opacity: 0;
	background: colors.$primary-pale;
	margin: 0 0 map.get(units.$spacing, 'small');
	visibility: hidden;
	transition: opacity 400ms ease-in-out;

	&.visible {
		height: auto;
		visibility: visible;
		opacity: 1;
	}

	&:before {
		content: '';
		position: absolute;
		right: 21px;
		top: -10px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 10px 8px;
		border-color: transparent transparent colors.$primary-pale transparent;
		transform: rotate(0deg);
	}

	&-content {
		overflow: hidden;
		max-height: 100%;
	}

	&-pages {
		display: flex;

		&.page-2 {
			.iban-calculator-scroller {
				transform: translateX( -50% );
			}
		}
	}

	&-scroller {
		flex: 0 0 200%;
		display: flex;
		transition: transform 300ms global.$easing;
	}

	&-page {
		flex: 0 0 50%;
		padding: map.get(units.$spacing, 'small');
	}

	&-results {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	ul.iban-calculator-results-list {
		list-style-type: none;
		padding-left: 0;
	}

	&-results-buttons {
		.form-button {
			width: 140px;
			margin-right: map.get(units.$spacing, 'small');
		}
	}

	&-close {
		position: absolute;
		top: map.get(units.$spacing, 'x-small');
		right: map.get(units.$spacing, 'x-small');
		padding: 4px 0 0;
		width: map.get(units.$spacing, 'large');
		height: map.get(units.$spacing, 'large');
		cursor: pointer;
		border: 0;
		background: none;
		transition: background 500ms;

		svg path {
			fill: colors.$gray-dark;
			transition: fill 500ms;
		}

		&:hover,
		&:focus {
			svg path {
				fill: color.adjust(colors.$gray-dark, $lightness: -20%);
			}
		}
	}
}

h2.icon-title {
	padding: map.get(units.$spacing, 'small') 0 0 1.5rem;
	margin: 0 map.get(units.$spacing, 'small');
	font-size: 18px;
	line-height: 25px;

	svg {
		float: left;
		margin: 4px 0 0 -1.5rem;
	}
}

</style>
